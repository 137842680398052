// These events only fire for new clients:
export function trackAppointmentStep({
  analytics,
  mixpanel,
  pageId,
  request,
  reservation,
  session,
}) {
  let { currentClient, currentPractice } = session;

  if (!currentClient) {
    let { careTypes, channel, clinician, flowId, mentalHealthHistory, message, reasonsForVisit } =
      reservation?.getProperties(
        'careTypes',
        'channel',
        'clinician',
        'flowId',
        'mentalHealthHistory',
        'message',
        'reasonsForVisit'
      ) ?? {};

    let eventProperties = {
      source: request?.sourceType ?? null,
      channel: channel?.name ?? null,
      'channel_id': channel?.id ?? null,
      'clinician_id': clinician?.id ?? null,
      'page_id': pageId,
      'reason_for_visit_message': !!message ?? null,
      'reason_for_visit_checkbox': !!reasonsForVisit?.length ?? null,
      'risk_assessment': !!mentalHealthHistory?.length ?? null,
      'medication_question': !!careTypes?.length ?? null,
    };

    analytics?.selectiveTrack('Appointment Request Flow Step Viewed', {
      object: 'Appointment Request Flow Step',
      action: 'Viewed',
      'flow_id': flowId ?? null,
      ...eventProperties,
    });

    Object.keys(eventProperties).forEach(key => {
      if (!eventProperties[key]) {
        delete eventProperties[key];
      }
    });

    mixpanel?.track(`user: appointment request flow step viewed`, {
      'feature_expand_prescreener_questions':
        currentPractice.get('featureExpandPrescreenerQuestions') ?? null,
      'feature_sticky_cta_booking_widget':
        currentPractice.get('featureStickyCtaBookingWidget') ?? null,
      'feature_booking_widget_medication_question':
        currentPractice.get('featureBookingWidgetMedicationQuestion') ?? null,
      'feature_widget_payment_method': currentPractice.get('featureWidgetPaymentMethod') ?? null,
      'feature_widget_payment_method_desktop':
        currentPractice.get('featureWidgetPaymentMethodDesktop') ?? null,
      ...eventProperties,
    });
  }
}
