import { action, computed } from '@ember/object';
import { bannerError, callbackError } from 'client-portal/utils/error-handling';
import { or, reads } from '@ember/object/computed';
import { roundToCurrency } from 'client-portal/helpers/format-money';
import { success } from 'client-portal/utils/banners';
import { task, timeout } from 'ember-concurrency';
import ENV from 'client-portal/config/environment';
import Service, { service } from '@ember/service';
import classic from 'ember-classic-decorator';
import generateUUID from 'ember-simplepractice/utils/generate-uuid';

@classic
export default class BillingModalsService extends Service {
  @service store;

  @service session;
  @service currentPractice;
  @service kountDeviceDataCollector;
  @service stripe;

  @reads('session.currentClient') client;

  @reads('client.clientBillingOverview.balanceDue') balanceDue;
  @reads('client.unpaidInvoices') unpaidInvoices;
  @reads('currentPractice.hasCustomStripeAccount') hasCustomStripeAccount;
  @or('persistPaymentTask.isRunning', 'persistPaymentWithPaymentMethodTask.isRunning') isPersisting;

  pdfModel = null;
  paymentModel = null;
  afterPersist = [];

  init() {
    super.init(...arguments);

    this.hidePdf = this.hidePdf.bind(this);
    this.showPayment = this.showPayment.bind(this);
    this.hidePayment = this.hidePayment.bind(this);
    this.createNewCard = this.createNewCard.bind(this);
    this.setDefaultCard = this.setDefaultCard.bind(this);
    this.setPaymentInvoice = this.setPaymentInvoice.bind(this);

    this.kountDeviceDataCollector.collectData();
  }

  @action
  showPdf(model) {
    this.set('pdfModel', model);
    model.set('opened', true);
  }

  hidePdf() {
    this.set('pdfModel', null);
  }

  showPayment(invoice = null) {
    let payment = this.store.createRecord('payment');
    let card = this._defaultCard();

    if (!this.currentPractice.get('featurePaymentMethodsLink')) {
      card = card || this._newCard();
    }

    payment.setProperties({
      card,
      _invoice: invoice,
    });

    this.set('paymentModel', payment);
    this.setPaymentInvoice(invoice);
  }

  hidePayment() {
    if (this.paymentModel.card && this.paymentModel.card.isNew) {
      this.paymentModel.card.deleteRecord();
    }
    this.paymentModel.deleteRecord();
    this.set('paymentModel', null);
  }

  createNewCard() {
    this.paymentModel.setProperties({ card: this._newCard() });
  }

  setDefaultCard() {
    this.paymentModel.card.deleteRecord();
    this.paymentModel.setProperties({ card: this._defaultCard() });
  }

  setPaymentInvoice(invoice = null) {
    let amount = invoice ? invoice.remainingAmount : this.balanceDue;

    this.paymentModel.setProperties({ invoice, amount });
  }

  async confirmExpressCheckout(elements, paymentOptions) {
    let { error: submitError } = await elements.submit();
    if (submitError) return;

    let paymentIntent = await this.createPaymentIntent(paymentOptions);
    let { error: confirmError } = await this.stripe.stripe.confirmPayment({
      elements,
      clientSecret: paymentIntent.clientSecret,
      redirect: 'if_required',
    });

    if (confirmError) return;

    this.refreshBillingProfile.perform(paymentIntent);

    return paymentIntent;
  }

  @computed('unpaidInvoices.@each.remainingAmount')
  get totalUnpaidAmount() {
    let totalUnpaid = this.unpaidInvoices.reduce((acc, invoice) => {
      return (acc += invoice.remainingAmount || 0);
    }, 0);

    return roundToCurrency(totalUnpaid);
  }

  @computed('unpaidInvoices.@each.remainingAmount', 'paymentModel.invoice')
  get invoiceIdsWithAmounts() {
    if (this.paymentModel.invoice) {
      let { id: invoiceId, remainingAmount: amount } = this.paymentModel.invoice;

      return [
        {
          invoiceId,
          amount,
        },
      ];
    } else {
      return this.unpaidInvoices.reduce((acc, invoice) => {
        let { id: invoiceId, remainingAmount: amount } = invoice;

        if (amount > 0) {
          acc.push({ invoiceId, amount });
        }

        return acc;
      }, []);
    }
  }

  @(task(function* () {
    this.paymentModel.set('_isDirty', true);
    let { card } = this.paymentModel;

    try {
      if (card.isNew) {
        yield card.validate();
        yield card.createToken();

        if (card.validations.isInvalid) {
          return;
        }
      }
      let embed = [];
      if (card.isNew && card._saveCard) {
        embed = ['card'];
      } else {
        this.paymentModel.set('customCardToken', card.customCardToken);
      }

      yield this.paymentModel.createRadarSession();
      yield this._createPayment(embed);

      yield Promise.all(this.afterPersist.map(x => x()));
      this.hidePayment();
      success({ title: 'Payment made successfully.' });
    } catch (err) {
      if (card.isNew && !card.validations.isValid) {
        return;
      }

      this._handlePaymentFailure(err);
    }
  }).keepLatest())
  persistPaymentTask;

  @(task(function* (elements) {
    this.paymentModel.set('_isDirty', true);
    let { card: newCard } = this.paymentModel;
    let embed = [];

    try {
      if (newCard.isNew) {
        let { paymentMethod } = yield this.stripe.stripe.createPaymentMethod({
          elements,
          params: {
            /* eslint-disable camelcase */
            billing_details: {
              name: newCard.name,
            },
          },
        });
        let { card } = paymentMethod;

        newCard.setProperties({
          brand: card.brand,
          last4: card.last4,
          expMonth: card.exp_month,
          expYear: card.exp_year,
          paymentMethodId: paymentMethod.id,
        });

        if (newCard._saveCard) {
          embed = ['card'];
        } else {
          this.paymentModel.set('customCardToken', paymentMethod.id);
        }
      }

      yield this._createPayment(embed);
      yield Promise.all(this.afterPersist.map(x => x()));

      /*
        Resets the Stripe instance because of payment method caching by createPaymentMethod
        This is a temporary fix for edge cases with this flow, which is only needed to help migrate to Payment Methods
        This instance resetting should be removed once the payment element handles payment intents
      */
      this.stripe.setup(this.currentPractice.get('customStripePublishableKey'));

      this.hidePayment();
      success({ title: 'Payment made successfully.' });
    } catch (err) {
      this._handlePaymentFailure(err);
    }
  }).keepLatest())
  persistPaymentWithPaymentMethodTask;

  async createPaymentIntent(paymentOptions) {
    try {
      return await this._persistPaymentIntent(paymentOptions);
    } catch (error) {
      this._handlePaymentFailure(error);
    }
  }

  @task(function* (paymentIntent) {
    let calls = 0;
    let maxCalls = 6;

    let invoiceId = paymentIntent.invoiceIdsWithAmounts[0].invoiceId;

    while (calls < maxCalls) {
      let invoice = yield this.store.findRecord('invoice', invoiceId, { reload: true });

      if (invoice.get('isPaid') || ENV.environment === 'test') {
        yield Promise.all(this.afterPersist.map(x => x()));

        return;
      } else {
        yield timeout(1000);
        calls++;
      }
    }

    yield Promise.all(this.afterPersist.map(x => x()));
  })
  refreshBillingProfile;

  async _persistPaymentIntent(paymentOptions) {
    let { invoiceIdsWithAmounts } = this;
    let { amount, currency } = paymentOptions;
    let paymentIntent = {
      amount,
      currency,
      paymentMethodTypes: ['card', 'link'],
      idempotencyKey: generateUUID(),
      useCredit: this._useCredit(),
      invoiceIdsWithAmounts,
    };
    return await this.store.createRecord('stripe-payment-intent', paymentIntent).save();
  }

  _defaultCard() {
    return this.client.cards.findBy('isDefault') || this.client.cards.firstObject;
  }

  _newCard() {
    let card = this.store.createRecord('card', {
      name: this.session.currentClientAccess.client.preferredName,
    });
    card.setProperties({
      _saveCard: true,
      isDefault: true,
    });
    return card;
  }

  _handlePaymentFailure(err) {
    callbackError(err, message => {
      let title = 'Payment could not be made';

      if (message.title.match(/Your account cannot currently make charges/)) {
        title = 'Your payment could not be processed';
        message.title = 'Contact your provider for more information.';
      }

      bannerError(err, { title });
      return true;
    });
  }

  async _createPayment(embed) {
    let { invoiceIdsWithAmounts } = this;

    await this.store.adapterFor('payment').createWithInvoiceItemPayments({
      ...this.paymentModel.serialize({
        adapterOptions: {
          embed,
        },
      }),
      meta: {
        useCredit: this._useCredit(),
        invoiceIdsWithAmounts,
      },
    });
  }

  _useCredit() {
    return !this.paymentModel.invoice && this.balanceDue < this.totalUnpaidAmount;
  }
}
