/* import __COLOCATED_TEMPLATE__ from './questionnaire-form.hbs'; */
import {
  AUDIT,
  GAD7,
  PCL5,
  PEG,
  PHQ15,
  PHQ9,
  WSAS,
  YBOCS,
} from 'client-portal/models/document-request-questionnaire';
import { action, computed, setProperties } from '@ember/object';
import { bannerError, callbackError } from 'client-portal/utils/error-handling';
import { classNames } from '@ember-decorators/component';
import { ensureSafeComponent } from '@embroider/util';
import { filterBy, reads } from '@ember/object/computed';
import { importSync } from '@embroider/macros';
import { modifier } from 'ember-modifier';
import { resetErrors } from 'client-portal/utils/validate-record';
import { service } from '@ember/service';
import { waitForRender } from 'ember-simplepractice/utils/waiters';
import Super from './autosave-form';
import classic from 'ember-classic-decorator';
import styles from './questionnaire-form.module.scss';
import stylesSuper from './contact-info-form.module.scss';

@classic
@classNames(styles.component)
export default class SiteDocumentsQuestionnaireForm extends Super {
  @service session;
  @service mixpanel;
  @service currentPractice;
  styles = styles;

  stylesSuper = stylesSuper;
  showModal = false;

  @reads('model') autosaveRecord;
  @reads('model.documentTitle') documentTitle;
  @reads('currentPractice.isMeasurementAuditEnabled') isMeasurementAuditEnabled;
  @reads('currentPractice.isMeasurementPcl5Enabled') isMeasurementPcl5Enabled;
  @reads('currentPractice.isMeasurementPegEnabled') isMeasurementPegEnabled;
  @reads('currentPractice.isMeasurementPhq15Enabled') isMeasurementPhq15Enabled;
  @reads('currentPractice.isMeasurementWsasEnabled') isMeasurementWsasEnabled;
  @reads('currentPractice.isMeasurementYbocsEnabled') isMeasurementYbocsEnabled;
  @filterBy(
    'templateQuestionsAndAnswers',
    'question',
    '9. Thoughts that you would be better off dead or of hurting yourself in some way.'
  )
  questionNine;
  @reads('questionNine.firstObject.answer.answers.firstObject.answer_text') questionNineAnswer;

  onModalSetupAndTeardown = modifier(
    () => {
      let footer = document.querySelector('footer');
      footer?.classList?.add('invisible');

      return () => {
        footer?.classList?.remove('invisible');
      };
    },
    { eager: false }
  );

  @computed('model.isReviewing', 'isMeasure', 'questionNineAnswer')
  get canShowModal() {
    return (
      this.isMeasure &&
      this.documentTitle === PHQ9 &&
      this.model.isReviewing &&
      !this.questionNineAnswer?.includes('Not at all')
    );
  }

  @computed('model.{templateQuestionsAndAnswers,userAnswersWithoutScores,isReviewing}', 'isMeasure')
  get templateQuestionsAndAnswers() {
    let { isReviewing, templateQuestionsAndAnswers, userAnswersWithoutScores } = this.model;
    let questionsAndAnswers = templateQuestionsAndAnswers.map(x => {
      let inputModule = importSync(
        `ember-simplepractice/components/shared/questionnaire/${x.normalizedType}-input`
      );
      x.inputComponent = ensureSafeComponent(inputModule.default, this);

      let reviewModule;
      if (['date', 'signature'].includes(x.normalizedType)) {
        reviewModule = importSync(`../../shared/questionnaire/${x.normalizedType}-review`);
      } else {
        reviewModule = importSync(
          `ember-simplepractice/components/shared/questionnaire/${x.normalizedType}-review`
        );
      }

      x.reviewComponent = ensureSafeComponent(reviewModule.default, this);

      return x;
    });

    if (this.isMeasure && isReviewing) {
      return questionsAndAnswers.map(x => {
        let answer = userAnswersWithoutScores.findBy('questionId', x.id);
        return { ...x, answer };
      });
    } else {
      return questionsAndAnswers;
    }
  }

  @action
  additionalClassNames(index) {
    if (!this.isMeasure) {
      return '';
    }

    let isDecoratableFirstQuestion =
      index === 0 && [GAD7, PHQ9, PHQ15].includes(this.documentTitle);
    let isDecoratableThirdQuestion = index === 2 && [PCL5, WSAS].includes(this.documentTitle);
    if (isDecoratableFirstQuestion || isDecoratableThirdQuestion) {
      return 'measurement-title-input';
    }
  }

  @computed(
    'isMeasurementAuditEnabled',
    'isMeasurementPcl5Enabled',
    'isMeasurementPegEnabled',
    'isMeasurementPhq15Enabled',
    'isMeasurementWsasEnabled',
    'isMeasurementYbocsEnabled',
    'model.mixpanelType'
  )
  get isMeasure() {
    let flag;
    switch (this.documentTitle) {
      case GAD7:
      case PHQ9:
        return this.model.mixpanelType === 'measure';
      case AUDIT:
        flag = 'isMeasurementAuditEnabled';
        break;
      case PCL5:
        flag = 'isMeasurementPcl5Enabled';
        break;
      case PEG:
        flag = 'isMeasurementPegEnabled';
        break;
      case PHQ15:
        flag = 'isMeasurementPhq15Enabled';
        break;
      case WSAS:
        flag = 'isMeasurementWsasEnabled';
        break;
      case YBOCS:
        flag = 'isMeasurementYbocsEnabled';
        break;
    }

    return flag && this.get(flag) && this.model.mixpanelType === 'measure';
  }

  @action
  async nextStep() {
    try {
      resetErrors(this.model);
      await this.completeRequestTask.perform(this.model);
      if (!(this.isDestroyed || this.isDestroying)) {
        this.set('autosaveData', {});
      }
    } catch (err) {
      callbackError(err, message => {
        if (message.status !== '422') {
          bannerError(err, { title: `${this.model.documentTitle} could not be submitted` });
        }
        return true;
      });

      await waitForRender();
      this.showValidationError();
    }
  }

  @action
  scrollToTop() {
    if (!this.model.isReview) {
      document.body.scrollTop = 0;
    }
  }

  @action
  editSignature(question) {
    this.setProperties({
      title: 'Edit your signature',
      showSignatureModal: question.id,
    });
  }

  @action
  sign(question) {
    if (this.session.signatoryName) {
      setProperties(question.answer, {
        isDirty: true,
        isSigned: true,
      });
    } else {
      this.setProperties({
        title: 'Create your signature',
        showSignatureModal: question.id,
      });
    }
  }

  @action
  onClose() {
    this.session.currentClient.rollbackAttributes();
    this.session.currentClientAccess.client.rollbackAttributes();
    this.set('showSignatureModal', undefined);
  }
}
