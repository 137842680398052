export const dateFormat = 'MM/DD/YYYY';
export const isoDateFormat = 'YYYY-MM-DD';
export const humanDateFormat = 'MMM DD, YYYY';
export const humanFullDateFormat = `ddd, ${humanDateFormat}`;
export const timeFormat = 'h:mm a';
export const dateTimeFormat = `${dateFormat} ${timeFormat}`;
export const humanDateTimeFormat = `MMMM D, YYYY ${timeFormat}`;
export const phraseDateTimeFormat = `${timeFormat} on ${dateFormat}`;
export const zoneFormat = 'z';

export default {
  dateFormat,
  isoDateFormat,
  humanDateFormat,
  humanFullDateFormat,
  timeFormat,
  dateTimeFormat,
  humanDateTimeFormat,
  zoneFormat,
};
