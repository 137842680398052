/* import __COLOCATED_TEMPLATE__ from './announcements.hbs'; */
import { reads } from 'macro-decorators';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import styles from './announcements.module.scss';

export default class SiteHeaderAnnouncements extends Component {
  @service announcement;

  styles = styles;

  constructor() {
    super(...arguments);
    this.announcement.fetchAnnouncements();
  }

  @reads('announcement.announcements') announcements;
}
