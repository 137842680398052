/* import __COLOCATED_TEMPLATE__ from './add-card-flow.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class SiteBillingPaymentModalCheckoutFlowsAddCardFlow extends Component {
  @service billingModals;
  @service currentPractice;
  @service stripePaymentElementService;
  @service stripePaymentModeElementService;

  get isPaymentSubmitDisabled() {
    if (this._hasPaymentMethodsEnabled()) {
      return !this.stripePaymentElementService.isLoaded || this.billingModals.isPersisting;
    }

    return this.billingModals.isPersisting || !this.args.model.card.areElementsReady;
  }

  @action
  exitAddingCard() {
    if (this._hasLinkEnabled()) {
      this.stripePaymentModeElementService.update({ paymentMethodTypes: ['card', 'link'] });
    }

    this.billingModals.setDefaultCard();
  }

  _hasLinkEnabled() {
    return this.currentPractice.get('featurePaymentMethodsLink');
  }

  _hasPaymentMethodsEnabled() {
    return this.currentPractice.get('featurePaymentMethods');
  }
}
