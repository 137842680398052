/* import __COLOCATED_TEMPLATE__ from './step-link.hbs'; */
import { and, reads } from '@ember/object/computed';
import { classNameBindings, classNames } from '@ember-decorators/component';
import { computed } from '@ember/object';
import { service } from '@ember/service';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './step-link.module.scss';

@classic
@classNameBindings(
  'isEnabled::disabled',
  'isCompleted:completed',
  'isFirst',
  'isLast',
  'isCondensedStep'
)
@classNames(styles.component)
export default class RequestStepsStepLink extends Component {
  @service request;
  @service analytics;
  @service mixpanel;
  @service session;
  @service currentPractice;

  @reads('step.canShowCondensedNav') canShowCondensedNav;
  @reads('step.isCondensed') isCondensed;
  @and('canShowCondensedNav', 'isCondensed') isCondensedStep;

  hasBeenTracked = false;

  @computed('isCondensedStep', 'steps.[]', 'step')
  get isLast() {
    let { isCondensedStep, steps, step } = this;
    if (isCondensedStep) {
      return steps.filterBy('isCondensed').lastObject === step;
    }
    return false;
  }

  @computed('isCondensedStep', 'steps.[]', 'step')
  get isFirst() {
    let { isCondensedStep, steps, step } = this;
    if (isCondensedStep) {
      return steps.filterBy('isCondensed').firstObject === step;
    }
    return false;
  }

  @computed('currentStep', 'isCondensed', 'steps.[]', 'model', 'step')
  get isCompleted() {
    let { isCondensedStep, currentStep, steps, model, step } = this;
    if (isCondensedStep) {
      let condensed = steps.filterBy('isCondensed');
      let incompleteStep = condensed.find(step => {
        return !model.get(step.completedKey);
      });
      return currentStep?.index > condensed.length - 1 && !incompleteStep;
    }

    return model.get(step.completedKey);
  }

  @computed(
    'canShowCondensedNav',
    'isCompleted',
    'channel.careCoordinatorMode',
    'step.{condensedTitle,careCoordinatorCompletedTitle,title,completedTitle}'
  )
  get linkText() {
    let { canShowCondensedNav, isCompleted, step, channel } = this;
    let { careCoordinatorMode } = channel || {};
    let { condensedTitle, careCoordinatorCompletedTitle, title, completedTitle } = step;

    if (canShowCondensedNav && condensedTitle) {
      return condensedTitle;
    }

    if (isCompleted) {
      return (careCoordinatorMode && careCoordinatorCompletedTitle) || completedTitle;
    }

    return (careCoordinatorMode && careCoordinatorCompletedTitle) || title;
  }

  @computed('canShowCondensedNav', 'steps.[]', 'step', 'index')
  get indexLabel() {
    let { canShowCondensedNav, steps, step, index } = this;
    let lastCondensedIndex = steps.filterBy('isCondensed').length - 1;
    if (canShowCondensedNav) {
      if (step.indexLabel) {
        return step.indexLabel;
      } else {
        return index - lastCondensedIndex;
      }
    } else {
      return index;
    }
  }

  get shouldTrack() {
    return !this.hasBeenTracked && this.currentStep && this.step.route === this.currentStep.route;
  }

  get clinicianTrackProperties() {
    return this.model.clinician ? { 'clinician_id': this.model.clinician.id } : {};
  }

  get dateLocationTrackProperties() {
    let currentStepRoute = this.currentStep.route;
    if (/(date)|(location)/.test(currentStepRoute)) {
      return {
        'service_is_call_to_book': this.model.cptCode?.callToBook,
      };
    }
    return {};
  }

  get trackViewedProperties() {
    let { request, currentStep, index, session, currentPractice } = this;
    let { currentClient, trackingSource, reorderedNav } = session;
    let { sourceType, hasFreeServices, hasRecommendedServiceBadge, findClientType } = request;
    let featureBookingWidgetMedicationQuestion = currentPractice.get(
      'featureBookingWidgetMedicationQuestion'
    );
    let featureWidgetPaymentMethod = currentPractice.get('featureWidgetPaymentMethod');

    return {
      'page_name': currentStep.title,
      'page_id': currentStep.id,
      'page_number': index,
      'source': sourceType,
      'offer_free_service': hasFreeServices,
      'recommended_badge_displayed': hasRecommendedServiceBadge,
      'client_type': findClientType(currentClient),
      'monarch_page': trackingSource,
      'feature_booking_widget_reordering_steps': reorderedNav,
      'feature_booking_widget_medication_question': featureBookingWidgetMedicationQuestion,
      'feature_widget_paymentMethod': featureWidgetPaymentMethod,
      ...this.clinicianTrackProperties,
      ...this.dateLocationTrackProperties,
    };
  }

  didRender() {
    super.didRender(...arguments);
    if (this.shouldTrack) {
      this.set('hasBeenTracked', true);
    }
  }
}
