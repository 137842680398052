import { isTest } from './is-testing';
import Swal from 'sweetalert2';
import config from 'ember-get-config';
import setupModals from './setup-modals';

let options = { target: config.APP.rootElement || 'body' };
if (isTest()) {
  // disable modal animations for testings
  options = {
    ...options,
    showClass: {
      container: '',
      popup: '',
      icon: '',
    },
    hideClass: {
      container: '',
      popup: '',
      icon: '',
    },
  };
}

const modals = setupModals(Swal);

const information = config => modals.information.fire({ ...config, ...options });
const confirmation = config => modals.confirmation.fire({ ...config, ...options });
const destruction = config => modals.destruction.fire({ ...config, ...options });
const custom = config => modals.custom.fire({ ...config, ...options });

export { information, confirmation, destruction, custom };
