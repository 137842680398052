import { WEBSITE_WIDGET_CHANNEL } from 'client-portal/services/website-scheduling-widget';
import { and, empty, equal, reads } from '@ember/object/computed';
import { computed, setProperties } from '@ember/object';
import { isEmberTesting } from 'ember-simplepractice/utils/is-testing';
import { relationshipName } from 'client-portal/utils/relationship-types';
import { service } from '@ember/service';
import { session } from 'client-portal/utils/persistent-hash-table';
import { sessionTimeoutMinutes } from 'client-portal/services/keepalive';
import { warning } from 'client-portal/utils/banners';
import SimpleAuthSessionService from 'ember-simple-auth/services/session';
import classic from 'ember-classic-decorator';

export const MONARCH_CHANNEL = 'monarch';
const TRACKING_SOURCE_VALUES = ['listing_page', 'clinician_profile_page'];
const FEATURE_TEST = 'test';
const FEATURE_NOEXP = 'noexp';
const UNDEFINED_VALUE = 'undefined';

@classic
export default class Session extends SimpleAuthSessionService {
  @service analytics;
  @service router;
  @service currentPractice;
  @service clientAppBanner;
  @service fastboot;
  @service clientTimezone;

  environment = null;

  #isHeaderFooterHidden = false;
  #trackingSource = null;
  #addMessage = false;
  #addReasonsForVisit = false;

  #reorderedNav = FEATURE_NOEXP;
  #addReasonsForVisitFlag = FEATURE_NOEXP;
  _noInvalidationBanner = false;

  set addMessage(value) {
    this.#addMessage = this.isMonarchEmbedded && value;
  }

  get addMessage() {
    return this.#addMessage;
  }

  set addReasonsForVisit(value) {
    this.#addReasonsForVisit = this.isMonarchEmbedded && value;
  }

  get addReasonsForVisit() {
    return this.#addReasonsForVisit;
  }

  set addReasonsForVisitFlag(value) {
    this.#addReasonsForVisitFlag = this.isMonarchEmbedded && value;
  }

  get addReasonsForVisitFlag() {
    return this.#addReasonsForVisitFlag;
  }

  set trackingSource(value) {
    if (this.isMonarchEmbedded && TRACKING_SOURCE_VALUES.includes(value)) {
      this.#trackingSource = value;
    }
  }

  get trackingSource() {
    return this.#trackingSource;
  }

  set reorderedNav(value) {
    if (typeof value !== UNDEFINED_VALUE) {
      this.#reorderedNav = this.isMonarchEmbedded && value;
    }
  }

  get reorderedNav() {
    return this.#reorderedNav;
  }

  get reorderedNavEnabled() {
    return this.#reorderedNav === FEATURE_TEST;
  }

  set isHeaderFooterHidden(value) {
    this.#isHeaderFooterHidden = value;
    if (!this.fastboot.isFastBoot) {
      session.set('isHeaderFooterHidden', !!value || '');
    }
  }

  @computed('fastboot.isFastBoot')
  get isHeaderFooterHidden() {
    if (!this.fastboot.isFastBoot && !this.#isHeaderFooterHidden) {
      return Boolean(session.get('isHeaderFooterHidden'));
    }
    return this.#isHeaderFooterHidden;
  }

  @computed(
    'currentPractice.featureExpandPrescreenerQuestions',
    'isSpWebsite',
    'isMonarchEmbedded',
    'isClientPortal'
  )
  get hasPrescreenerFeatures() {
    let { currentPractice, isSpWebsite, isMonarchEmbedded, isClientPortal } = this;
    return (
      currentPractice?.get('featureExpandPrescreenerQuestions') &&
      (isMonarchEmbedded || isSpWebsite || isClientPortal)
    );
  }

  @reads('currentPractice.practiceWebsite') isPracticeWebsite;

  @reads('environment.currentClientAccess') currentClientAccess;

  @reads('environment.currentClient') currentClient;

  @reads('environment.currentClientOptions') currentClientOptions;

  @reads('currentClientAccess.client.signatoryName') signatoryName;

  @reads('currentClient.relationshipToCurrentClientAccess') clientRelationshipType;

  @equal('channel', WEBSITE_WIDGET_CHANNEL) isSpWebsite;

  @equal('channel', MONARCH_CHANNEL) isMonarchEmbedded;

  @empty('channel') isClientPortal;

  @reads('router.currentRouteName') currentRouteName;

  @reads('currentPractice.canUseNativeDocRendering') canUseNativeDocRendering;

  @reads('currentPractice.hasClientappDocumentAccess') hasClientappDocumentAccess;

  @and('currentPractice.featureStickyCtaBookingWidgetEnabled', 'isMonarchEmbedded')
  stickyCtaBookingWidgetEnabled;

  @computed('clientRelationshipType')
  get clientRelationshipName() {
    return relationshipName(this.clientRelationshipType);
  }

  @computed('currentRouteName', 'isHeaderFooterHidden', 'hasClientappDocumentAccess')
  get isDocumentsRouteRedirect() {
    let { currentRouteName, isHeaderFooterHidden, hasClientappDocumentAccess } = this;

    return (
      currentRouteName.includes('documents') && hasClientappDocumentAccess && isHeaderFooterHidden
    );
  }

  @computed('currentRouteName')
  get isWidget() {
    return this.currentRouteName.includes('widget');
  }

  @computed('isWidget')
  get canUsePaymentMethods() {
    return this.currentPractice.get('featurePaymentMethods') && !this.isWidget;
  }

  signedOutBanner() {
    warning({
      title: `You have been logged out for security reasons due to being inactive for ${sessionTimeoutMinutes} minutes.`,
      isTransient: false,
    });
  }

  async signOut() {
    setProperties(this.data, {
      skipLicenseAgreement: false,
      skipLegalAgreements: false,
      redirect: undefined,
    });

    this.clientAppBanner.resetDismiss();
    this.clientTimezone.reset();

    this.analytics.clearAttributes();

    let restartAppNeeded =
      this.currentPractice.get('hasClientappDocumentAccess') ||
      this.currentPractice.get('canUseNativeDocRendering');

    let redirectPath;
    if (restartAppNeeded) {
      redirectPath = '/session-timeout';
    } else if (this._noInvalidationBanner) {
      redirectPath = '/';
    } else {
      redirectPath = '/sign-in?signedOut';
    }

    if (this.fastboot.isFastBoot) {
      this.fastboot.response.statusCode = 302;
      this.fastboot.response.headers.set('location', redirectPath);
    } else {
      session.remove('isHeaderFooterHidden');

      let newLocation = this._isSignInPath ? location.href : redirectPath;
      if (isEmberTesting()) {
        await this.router.transitionTo(newLocation);
      } else {
        if (location.href === newLocation) {
          location.reload();
        } else {
          location.href = newLocation;
        }
      }
    }
  }

  handleAuthentication() {
    // noop
  }

  handleInvalidation() {
    super.handleInvalidation(...arguments);
    return this.signOut();
  }

  get _isSignInPath() {
    let currentPath = this.fastboot.isFastBoot ? this.fastboot.request.path : location.pathname;
    return currentPath && currentPath.indexOf('/sign-in') === 0;
  }
}
