import { attr, belongsTo, hasMany } from '@ember-data/model';
import { equal } from '@ember/object/computed';
import classic from 'ember-classic-decorator';

import UnauthenticatedAppointmentModel from './unauthenticated-appointment';

@classic
export default class Appointment extends UnauthenticatedAppointmentModel {
  @belongsTo('treatable-client', { polymorphic: true, async: false }) client;
  @belongsTo('card', { async: false }) card;
  @belongsTo('insurance-claim', { async: false }) insuranceClaim;
  @hasMany('invoice-item', { async: false }) invoiceItems;
  @belongsTo('superbill', { async: false, inverse: 'appointments' }) superbill;
  @attr() schedulingSource;
  @attr() uninvoicedFee;
  @attr() videoRoomUrl;
  @attr() billableDescription;
  @attr() channel;
  @attr() mld;
  @attr() fee;
  @attr() cptCodes;
  @attr() units;
  @equal('confirmationStatus', 'Pending') isPending;

  get totalAllocatedAmount() {
    return this.invoiceItems.reduce((total, invoiceItem) => {
      return total + invoiceItem.allocatedAmount;
    }, 0);
  }

  get paid() {
    return this.uniqueInvoices.every(invoice => invoice.isPaid);
  }

  get uniqueInvoices() {
    return this.invoiceItems.map(invoiceItem => invoiceItem.invoice).uniqBy('id');
  }
}
