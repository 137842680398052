import { action } from '@ember/object';
import { callbackError } from 'client-portal/utils/error-handling';
import { environmentRelationships } from 'client-portal/authenticators/environment';
import { htmlSafe } from '@ember/template';
import { pluralize } from 'ember-inflector';
import { service } from '@ember/service';
import FastbootHelpersMixin from 'client-portal/mixins/route/fastboot-helpers';
import Route from '@ember/routing/route';
import classic from 'ember-classic-decorator';
import uuidv4 from 'uuid/v4';

@classic
export default class ApplicationRoute extends Route.extend(FastbootHelpersMixin) {
  @service mixpanel;
  @service store;
  @service currentPractice;
  @service session;
  @service request;
  @service analytics;
  @service stripe;
  @service keepalive;
  @service clientAppBanner;
  @service clientTimezone;

  init() {
    super.init(...arguments);
    if (!this.isFastBoot) {
      this.keepalive.start();
    }
  }

  model() {
    let environment = this.hasShoebox && this.store.peekAll('environment').firstObject;
    if (!environment) {
      let models = [
        'environment',
        'practice',
        'office',
        'phone',
        'practice-website',
        'client',
        'client-access',
        'treatable-client',
      ];

      let relationships = [
        'currentPractice',
        'currentClientAccess',
        'currentClient',
        'currentClientOptions',
      ];

      let fields = models.reduce((acc, modelName) => {
        let model = this.store.modelFor(modelName);
        acc[pluralize(model.modelName)] = model.fieldsFor('environment').join(',');
        return acc;
      }, {});

      fields.environments += `,${relationships.join(',')}`;

      environment = this.store.queryRecord('environment', {
        include: environmentRelationships.join(','),
        fields,
        redirect: this.redirectTo,
      });
    }
    this.set('redirectTo', null);
    return environment;
  }

  async beforeModel(transition) {
    await this.session.setup();

    let {
      channel,
      mld,
      appearance,
      redirect,
      trackingSource,
      addMessage,
      reorderedNav,
      addReasonsForVisit,
      addReasonsForVisitFlag,
    } = transition.to.queryParams;
    let colorSetId, theme;

    try {
      ({ colorSetId, theme } = JSON.parse(appearance));
    } catch (_err) {
      // noop
    }

    this.session.setProperties({
      channel,
      mld,
      colorSetId,
      theme,
      trackingSource,
      addMessage,
      reorderedNav,
      addReasonsForVisit,
      addReasonsForVisitFlag,
    });

    if (redirect) {
      this.session.set('data.redirect', redirect);
      this.set('redirectTo', redirect);

      if (redirect.includes('documents')) {
        this.session.set('isHeaderFooterHidden', true);
      }
    }

    super.beforeModel(...arguments);
  }

  afterModel(environment) {
    super.afterModel(...arguments);

    if (!environment) {
      return;
    }

    let { currentPractice } = environment;
    if (!currentPractice) {
      return;
    }

    this.clientTimezone.practiceTimezone = currentPractice.timezone;

    this.currentPractice.init(currentPractice);
    this.mixpanel.initClient(currentPractice.mixpanelKey);
    this.stripe.setup(currentPractice.customStripePublishableKey);

    this.session.authenticate('authenticator:environment', environment);
    if (!this.session.data.sessionId) {
      this.session.set('data.sessionId', uuidv4());
    }

    if (window._bugsnagClient && this.session.currentClientAccess) {
      window._bugsnagClient.setUser(this.session.currentClientAccess.id);
    }

    if (this.session.currentClient) {
      this.analytics.identify(this.session.currentClient);
    }
  }

  setupController(controller, model) {
    super.setupController(...arguments);

    if (model) {
      controller.set('messagingEmbed', htmlSafe(model.messagingEmbed));
    }
  }

  @action
  error(err, transition) {
    return callbackError(err, ({ status }) => {
      console.error(err); // eslint-disable-line no-console
      window._bugsnagClient && window._bugsnagClient.notify(err);

      if (status === '401') {
        // PRIVATE: stolen from how error substates are handled
        // This is to make fastboot return 3xx instead of 500
        // https://github.com/emberjs/ember.js/blob/v3.28.0/packages/%40ember/-internals/routing/lib/system/router.ts#L1193
        this._router._markErrorAsHandled(err);
        transition.abort();

        if (this.session._isSignInPath && this.isFastBoot) {
          return;
        }

        if (this.session._isSignInPath && !transition._attemptedRetry) {
          transition._attemptedRetry = true;
          transition.retry();
          return;
        }

        this.session.signOut();
        return;
      }

      // Unknown and API error
      if (!status || status === '502') {
        status = '500';
      }

      // Bare host request
      if (status === '302') {
        status = '404';
        // used in application error template
        err.errors[0].status = '404';
      }

      if (this.isFastBoot) {
        this.set('fastboot.response.statusCode', status);
      }

      // bubble to render substate error template
      return true;
    });
  }

  @action
  refreshEnvironment() {
    this.store.unloadRecord(this.currentModel);
    return this.refresh();
  }
}
