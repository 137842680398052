/* import __COLOCATED_TEMPLATE__ from './sign-out.hbs'; */
import { action } from '@ember/object';
import { bannerError } from 'client-portal/utils/error-handling';
import { classNames } from '@ember-decorators/component';
import { namespace } from 'client-portal/adapters/application';
import { service } from '@ember/service';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './sign-out.module.scss';

@classic
@classNames(styles.component)
export default class SiteSignOut extends Component {
  @service store;
  @service session;
  @service clientAppBanner;

  @action
  async signOut() {
    try {
      let adapter = this.store.adapterFor('application');
      await adapter.ajax(`/${namespace}/sessions`, 'DELETE', {});

      this.session.set('_noInvalidationBanner', true);
      this.clientAppBanner.resetDismiss();
      this.session.invalidate();
    } catch (err) {
      bannerError(err, { title: 'Could not sign out' });
    }
  }
}
