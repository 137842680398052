import { action, setProperties } from '@ember/object';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';

@classic
export default class SharedQuestionnaireDateInput extends Component {
  @action
  handleSetDate(value) {
    setProperties(this.answer, {
      isDirty: true,
      date: value,
    });
  }
}
