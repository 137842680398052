import { STEP_IDS } from 'client-portal/services/widget-steps';
import { callbackError } from 'client-portal/utils/error-handling';
import { error, info } from 'client-portal/utils/banners';
import { handleTransitionError } from 'client-portal/utils/handle-transition-error';
import { isoDateFormat } from 'client-portal/utils/date-time';
import { service } from '@ember/service';
import { task, timeout } from 'ember-concurrency';
import ENV from 'client-portal/config/environment';
import RequestBaseRoute from './base';
import classic from 'ember-classic-decorator';
import emberO, { action, get } from '@ember/object';
import moment from 'moment-timezone';

@classic
export default class RequestDate extends RequestBaseRoute {
  @service router;
  @service analytics;
  @service mixpanel;
  @service media;
  @service currentPractice;
  @service fastboot;
  @service store;
  @service session;
  @service request;
  @service pendingAppointment;
  @service clientTimezone;

  templateName = '-request/date';
  stepId = STEP_IDS.SELECT_DATE;
  queryParams = {
    currentDate: { refreshModel: true },
    ...(this.fastboot.isFastBoot ? {} : { currentTimeZone: { refreshModel: true } }),
  };

  @(task(function* (reservation, params) {
    try {
      // Skip fields from backend, we only care if it's saved
      // Mostly for startTime so local TZ is preserved
      yield reservation.save({
        adapterOptions: {
          query: { fields: { reservations: 'id' } },
        },
      });

      // Disable timeout for testing.
      if (ENV.environment !== 'test') {
        yield timeout(250);
      }

      let clientTypes = reservation.practice.clientTypesAllowedToBookAppt;

      if (this.session.currentClient) {
        clientTypes = ['client'];
      }

      // Block on transition, useful for a case when appointment
      // will persist (for existing client)
      if (clientTypes.length > 1) {
        try {
          yield this.request.transitionTo(this, 'client-type', params);
        } catch (err) {
          handleTransitionError(err);
        }
      } else {
        reservation.set('clientType', clientTypes[0]);

        try {
          yield this.request.transitionTo(this, this.getNextStepForFeatures(), params);
        } catch (err) {
          handleTransitionError(err);
        }
      }
    } catch (err) {
      callbackError(err, message => {
        error({ title: message.title });
        return true;
      });

      if (reservation.get('isNew')) {
        reservation.set('startTime', null);
      } else {
        reservation.rollbackAttributes();
      }

      this.refresh();
    }
  })
    .keepLatest()
    .maxConcurrency(1))
  persistReservationTask;

  beforeModel({ to: { queryParams } }) {
    let reservation = this.modelFor(this.request.baseRouteName);
    if (!reservation.belongsTo('office').id()) {
      this.request.transitionTo(this, 'location');
    }

    if (queryParams.currentTimeZone) {
      this.clientTimezone.updateDefault(queryParams.currentTimeZone);
    } else {
      this.clientTimezone.reset();
    }

    this._unloadSlots();
  }

  model({ currentDate, currentTimeZone }, transition) {
    let reservation = this.modelFor(this.request.baseRouteName);
    let clinicianId = reservation.get('clinician.id');
    let cptCodeId = reservation.get('cptCode.id');
    let officeId = reservation.get('office.id');
    let globalMonarchChannelId = reservation.get('channel.id');

    let startDate = moment
      .max(moment(this._startDate(currentDate)), moment())
      .startOf('minute')
      .toISOString();
    let endDate = this._endDate(startDate);
    let timeZone = currentTimeZone || this.clientTimezone.timezone;

    if (transition.to.queryParams.selectedSlot) {
      transition.trigger(false, 'preselectionLoading');
    }

    return this.store.query('slot', {
      filter: {
        clinicianId,
        cptCodeId,
        officeId,
        startDate,
        endDate,
        timeZone,
        globalMonarchChannelId,
      },
    });
  }

  afterModel(model, transition) {
    super.afterModel(...arguments);

    let { queryParams } = transition.to;
    if (queryParams.selectedSlot && model) {
      let spotTime = moment(queryParams.selectedSlot);
      let slot = model.findBy('id', spotTime.format(isoDateFormat));
      let spot = this.findSpot(slot, spotTime, this.modelFor(this.request.baseRouteName));

      if (spot && !this.session.currentClient) {
        return transition.trigger(false, 'nextStep', spot, { queryParams });
      }
    }
    transition.trigger(false, 'stopPreselectionLoading', transition);
    this.pendingAppointment.reset();
  }

  findSpot(slot, spotTime, reservation) {
    return slot?.spots?.reduce((acc, spot) => {
      let start = moment(spot.start);
      let end = moment(spot.end);

      let minimumBookTime = moment().add(
        this.currentPractice.get('selfScheduleMinimumLeadTime'),
        'seconds'
      );
      let callToBook = reservation.cptCode.callToBook || start.isSameOrBefore(minimumBookTime);

      if (start.isSame(spotTime) && !callToBook) {
        return emberO.create({
          start,
          end,
          callToBook,
        });
      }

      return acc;
    }, null);
  }

  setupController(controller, model) {
    super.setupController(...arguments);

    let reservation = this.modelFor(this.request.baseRouteName);
    let persistReservationTask = this.persistReservationTask;
    let findSpot = this.findSpot;
    let hasMultipleLocations = this.store.peekAll('office').get('length') > 1;
    controller.setProperties({
      reservation,
      persistReservationTask,
      hasMultipleLocations,
      findSpot,
    });

    if (!controller.currentDate) {
      let currentDate = this._defaultDate();
      controller.setProperties({ currentDate });
    }
    if (!controller.currentTimeZone) {
      let currentTimeZone = this.clientTimezone.timezone;
      controller.setProperties({ currentTimeZone });
    }

    this.setupFilteredSlots(reservation, controller);

    Object.keys(controller.filters).forEach(filter => {
      let disabled = !model.any(x => x.get(`${filter}Spots.length`));
      controller.set(`filters.${filter}.disabled`, disabled);
    });
    this.controller.send('resetActiveFilter');

    // Redirect if current month does not have the slots but next month does
    if (!controller._redirectAttempted) {
      let allDates = this.store.peekAll('slot').mapBy('date');
      let currentPrefix = moment().format('YYYY-MM');
      let hasCurrent = allDates.find(x => x.indexOf(currentPrefix) === 0);
      let nextPrefix = moment().add(1, 'month').format('YYYY-MM');
      let hasNext = allDates.find(x => x.indexOf(nextPrefix) === 0);

      if (!hasCurrent && hasNext) {
        this.router.transitionTo({
          queryParams: {
            currentDate: moment().add(1, 'month').startOf('month').format(isoDateFormat),
          },
        });
      }

      controller._redirectAttempted = true;
    }
  }

  resetController(controller, isExiting, _transition) {
    super.resetController(...arguments);

    if (isExiting) {
      this._unloadSlots();
      controller._redirectAttempted = undefined;
    }
  }

  @action
  setCurrentDate(date) {
    this.router.transitionTo({
      queryParams: {
        currentDate: date.startOf('month').format(isoDateFormat),
      },
    });
  }

  @action
  setCurrentTimeZone(currentTimeZone) {
    this.clientTimezone.updateDefault(currentTimeZone);
    this.router.transitionTo({
      queryParams: {
        currentTimeZone,
      },
    });
  }

  @action
  setSelectedDate(date) {
    let selectedDate = date.format(isoDateFormat);
    let { availableDates } = this.controller.get('filteredSlots');
    let selectedSlot = availableDates[selectedDate];
    this.controller.setProperties({ selectedDate, selectedSlot });
  }

  @action
  setFilter(name, e) {
    this.controller.set(`filters.${name}.checked`, e.target.checked);
    this.setupFilteredSlots(this.modelFor(this.request.baseRouteName), this.controller);
    this.controller.send('resetActiveFilter');
  }

  @action
  nextStep(spot, params) {
    let reservation = this.modelFor(this.request.baseRouteName);

    if (spot.callToBook) {
      if (this.media.isMdDown) {
        let { phone } = reservation.office;
        let practiceName = get(this, 'currentPractice.fullName');
        let msg = phone ? `call ${practiceName} at ${phone}.` : `contact ${practiceName}.`;
        info({
          title: `This time slot cannot be booked online.\nTo request this appointment, please ${msg}`,
          isSticky: true,
          classNames: 'info-with-icon has-icon',
        });
      }

      return;
    }

    reservation.set('startTime', spot.get('start').clone());
    this.persistReservationTask.perform(reservation, params);
  }

  @action
  didTransition() {
    let reservation = this.modelFor(this.request.baseRouteName);
    reservation.setProperties({
      clientType: null,
      message: null,
      hasCompletedMessageStep: false,
      channelFields: null,
      channelUploads: null,
      channelUploadsComplete: null,
    });

    this._trackAppointmentStep(reservation);
    return true;
  }

  setupFilteredSlots(reservation, controller) {
    let filteredSlots = this.filteredSlots(controller);
    let { availableDates } = filteredSlots;
    let availableDateValues = Object.keys(availableDates);
    let activeDate;

    if (get(reservation, 'cptCode.callToBook')) {
      activeDate = availableDateValues[0];
    }

    if (reservation.get('isReserved')) {
      activeDate = reservation.get('pristineStartTime').format(isoDateFormat);
    } else if (availableDates[controller.selectedDate]) {
      activeDate = controller.selectedDate;
    }

    let { selectedDate, selectedSlot, activeFilter } = this.findActiveSlot(
      availableDates,
      activeDate,
      controller
    );

    controller.setProperties({
      filteredSlots,
      selectedDate,
      selectedSlot,
      activeFilter,
    });
  }

  findActiveSlot(availableDates, selectedDate, controller) {
    let selectedSlot = availableDates[selectedDate];
    let availableDateValues = Object.keys(availableDates);
    let activeFilter = controller.get('activeFilter');
    if (!selectedSlot) {
      let minimumBookTime = moment().add(
        this.currentPractice.get('selfScheduleMinimumLeadTime'),
        'seconds'
      );
      availableDateValues.some(availableDate => {
        let activeSlot = availableDates[availableDate].spots.find(({ start }) =>
          moment(start).isAfter(minimumBookTime)
        );

        if (activeSlot) {
          selectedSlot = availableDates[availableDate];
          selectedDate = moment(activeSlot.start, isoDateFormat).format(isoDateFormat);
          activeFilter = selectedSlot.getFilterForDate(moment(activeSlot.start));
          return true;
        }
      });
    }
    return { selectedDate, selectedSlot, activeFilter };
  }

  filteredSlots(controller) {
    let current = moment(controller.currentDate);
    let now = moment();
    let after = current.clone().startOf('month');
    let until = current.clone().endOf('month').add(7, 'days');

    let disabledDates = [];
    let availableDates = {};
    for (
      let date = after.clone().subtract(7, 'days');
      until.isSameOrAfter(date);
      date.add(1, 'days')
    ) {
      let isFuture = now.isSameOrBefore(date, 'day');
      let isSameMonth = after.isSame(date, 'month');
      let slot = this.store.peekRecord('slot', date.format(isoDateFormat));
      let hasAvailability =
        slot &&
        Object.keys(controller.filters).find(filter => {
          if (!controller.get(`filters.${filter}.checked`)) {
            return false;
          }
          return slot.get(`${filter}Spots.length`);
        });

      if (isFuture && isSameMonth && hasAvailability) {
        availableDates[date.format(isoDateFormat)] = slot;
      } else {
        disabledDates.push(date.clone());
      }
    }

    return { disabledDates, availableDates };
  }

  _startDate(currentDate) {
    let reservation = this.modelFor(this.request.baseRouteName);
    let firstAvailableAppointmentOffsetHours =
      reservation?.channel?.firstAvailableAppointmentOffsetHours;
    let offsetDate = moment().add(firstAvailableAppointmentOffsetHours, 'hours');

    if (firstAvailableAppointmentOffsetHours && moment(currentDate) <= offsetDate) {
      return offsetDate;
    }

    return currentDate || this._defaultDate();
  }

  _endDate(startDate) {
    let reservation = this.modelFor(this.request.baseRouteName);
    let lastAvailableAppointmentOffsetHours =
      reservation?.channel?.lastAvailableAppointmentOffsetHours;
    let endTime = moment(startDate).endOf('month');

    // For a current month, lookahead next month as well
    if (moment(startDate).isSame(moment(), 'month')) {
      endTime = moment(startDate).add(1, 'month').endOf('month');
    }

    let maxOffsetTime = moment().add(lastAvailableAppointmentOffsetHours, 'hours');

    if (lastAvailableAppointmentOffsetHours && maxOffsetTime < endTime) {
      endTime = maxOffsetTime;
    }

    return endTime.toISOString();
  }

  _defaultDate() {
    return moment().startOf('month').format(isoDateFormat);
  }

  _unloadSlots() {
    this.store.peekAll('slot').forEach(model => model.unloadRecord());
  }
}
