/* import __COLOCATED_TEMPLATE__ from './couple-block.hbs'; */
import { and, reads } from '@ember/object/computed';
import { classNames } from '@ember-decorators/component';
import { computed } from '@ember/object';
import { service } from '@ember/service';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './couple-block.module.scss';

@classic
@classNames(styles.component)
export default class RequestInformationCoupleBlock extends Component {
  @service currentPractice;
  @service session;
  @service media;

  @reads('currentPractice.featureWidgetPaymentMethodDesktopEnabled')
  featureWidgetPaymentMethodDesktop;
  @and('currentPractice.featureWidgetPaymentMethodEnabled', 'session.isMonarchEmbedded')
  canDisplayPaymentOption;

  @computed('careCoordinatorMode')
  get labelPrefix() {
    return this.careCoordinatorMode ? '' : 'Your ';
  }

  get isDesktopFeatureEnabled() {
    return this.featureWidgetPaymentMethodDesktop && !this.media.isXsDown;
  }

  get dropdownPrimaryInstructions() {
    return this.isDesktopFeatureEnabled ? 'Choose file' : 'Upload a file';
  }

  get dropdownSecondaryInstructions() {
    return this.isDesktopFeatureEnabled ? ' or drag and drop file' : ' or take a picture';
  }

  get fieldClass() {
    return this.isDesktopFeatureEnabled ? 'col-lg-12' : 'col-lg-6';
  }
}
