import { action } from '@ember/object';
import { service } from '@ember/service';
import { trackAppointmentStep } from 'client-portal/utils/event-tracking';
import Route from '@ember/routing/route';
import classic from 'ember-classic-decorator';

@classic
export default class RequestBaseRoute extends Route {
  @service session;
  @service request;
  @service mixpanel;
  @service analytics;

  stepId = null;

  reveal() {
    return true;
  }

  nextStep(prop, val, route, params) {
    let reservation = this.modelFor(this.request.baseRouteName);
    reservation.set(prop, val);
    this.request.transitionTo(this, route, params);
  }

  skipStepWithPreselection(model, transition, paramName) {
    let { queryParams } = transition.to;
    if (queryParams[paramName]) {
      let found = model.findBy('id', queryParams[paramName].toString());
      if (found) {
        transition.trigger(false, 'nextStep', found, { queryParams });
        return true;
      }
    }
    transition.trigger(false, 'stopPreselectionLoading', transition);
  }

  get canShowPrescreenerNav() {
    let { session, request } = this;
    let { hasPrescreenerFeatures } = session;
    let { clinician } = this.modelFor(request.baseRouteName) || {};
    return hasPrescreenerFeatures && clinician?.hasMonarchProfession;
  }

  getNextStepForFeatures() {
    let { canShowPrescreenerNav, session } = this;
    let { addMessage, channel, currentClient } = session;
    let nextStep = 'information';

    if ((addMessage || canShowPrescreenerNav) && !currentClient) {
      nextStep = this.reorderedNextRoute();
    } else if (channel) {
      nextStep = 'channel-uploads';
    }
    return nextStep;
  }

  reorderedNextRoute() {
    let { reorderedNavEnabled, channel } = this.session;
    let nextStep = 'message';
    if (reorderedNavEnabled) {
      nextStep = channel ? 'channel-uploads' : 'information';
    }
    return nextStep;
  }

  @action
  didTransition() {
    let reservation = this.modelFor(this.request.baseRouteName);
    this._trackAppointmentStep(reservation);
    return true;
  }

  _trackAppointmentStep(reservation) {
    trackAppointmentStep({
      analytics: this.analytics,
      session: this.session,
      mixpanel: this.mixpanel,
      request: this.request,
      pageId: this.stepId,
      reservation,
    });
  }
}
