import { reads } from 'macro-decorators';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import Service, { service } from '@ember/service';

export default class AnnouncementService extends Service {
  @service currentPractice;
  @service router;
  @service session;
  @service store;

  @tracked announcements = [];

  @reads('queryAnnouncements.isRunning') isLoading;

  get isAnnouncementsActive() {
    let isClientSelectionPage = this.router.currentRouteName === 'client-selection';
    return !isClientSelectionPage && this.currentPractice.get('announcementsAvailable');
  }

  async fetchAnnouncements() {
    if (this.isAnnouncementsActive) {
      await this.queryAnnouncements.perform();
    }
  }

  queryAnnouncements = task(async () => {
    let announcements = await this.store.query('announcement', {});
    this.announcements = announcements;
  });
}
